<template>
  <BaseCard
    :caption-visible="false"
    :footer-visible="false"
    actions-visible
  >
    <template #title>
      <span class="h3 font-weight-bold mb-0 text-uppercase">no conformidades</span>
    </template>
    <template #actions>
      <b-link
        v-access="{
          resource: $data.$constants.RESOURCES.RESOURCE_NON_CONFORMITIES,
          resourceAction: $data.$constants.RESOURCE_ACTIONS.RESOURCE_ACTION_ALL
        }"
        :to="{ name: 'createNonConformity' }"
      >
        <b-button
          variant="primary"
          class="text-nowrap rounded-sm px-2"
        >
          Añadir no conformidad
        </b-button>
      </b-link>
    </template>
    <NonConformitiesTable hide-totals />
  </BaseCard>
</template>

<script>
import NonConformitiesTable from '@/components/non-conformities/table/NonConformitiesTable.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
  components: { BaseCard, NonConformitiesTable },
}
</script>

<style scoped>
</style>
